import dynamic from 'next/dynamic';
import { LibHeroContainerProps } from '@components/HeroContainer';
import { AssetInfo } from '@components/PropertyDetailMedia';
import { PropertyDetails } from '@components/PropertyDetail';
import { Header } from '@foxtons/fdl/components/Header/index';
import React from 'react';
import {
  LONG_LET,
  SALE,
  SHORT_LET
} from './Constants/propertySearchPageConstant';
import propertyListPagePathConstant from '@foxtons/fdl/utils/Constants/propertyListPagePathConstant';
import { HttpResponseDataType } from './httpRequestConfigs/httpRequests';
import { BreadcrumbItem } from '../types/common';

import format from 'date-fns/format';

export type HeaderProps = React.ComponentProps<typeof Header>;

export type PropsHeaderProps = HeaderProps['propsHeader'];

export type HeaderType = {
  id: number;
  attributes: {
    order: number;
    title: string;
    url: string;
    target: null;
    createdAt: string;
    updatedAt: string;
    children: {
      data: {
        id: number;
        attributes: {
          order: number;
          title: string;
          url: string;
          target: null;
          createdAt: string;
          updatedAt: string;
          identifier: string;
          children: {
            data: {
              id: number;
              attributes: {
                order: number;
                title: string;
                url: string;
                target: null;
                createdAt: string;
                updatedAt: string;
                children: {
                  data: never[];
                };
              };
            }[];
          };
        };
      }[];
    };
  };
}[];

/* eslint-disable no-useless-escape */
export const ukLongPostCodeRegex =
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

export const isUKLongPostCode = (value: string) => {
  if (!value) {
    return;
  }

  return ukLongPostCodeRegex.test(value.replaceAll('-', ''));
};

export const getBulletPoints = (
  richText: string,
  section: string,
  keyName = 'point'
) => {
  const bulletPoints = richText.split(/\n/g);

  return bulletPoints.map((item: string, index: number) => ({
    id: `${section}_${index}`,
    [keyName]: item.replace('- ', '')
  }));
};

export const getResponseImage = (
  imageUrl: string,
  fallbackImg = '/webImages/bg_Appointment.png'
) => {
  const imgUrl = imageUrl;

  if (imgUrl) {
    return `${process.env.NEXT_PUBLIC_CMS_CDN_URL}${imgUrl}`;
  }

  return fallbackImg;
};

export const getHeroBannerImage = (
  imageUrl: string,
  fallbackImg = '/webImages/bg_Appointment.png'
) => {
  if (imageUrl) {
    return getResponseImage(imageUrl);
  }

  // const imgUrl = imageUrl;

  // if (imgUrl) {
  //   return `${process.env.NEXT_PUBLIC_CMS_CDN_URL}${imgUrl} 1x, ${
  //     process.env.NEXT_PUBLIC_CMS_CDN_URL
  //   }${imgUrl} 2400w, ${process.env.NEXT_PUBLIC_CMS_CDN_URL}${imgUrl.slice(
  //     0,
  //     imgUrl.lastIndexOf('/')
  //   )}/medium_${imgUrl.slice(
  //     imgUrl.lastIndexOf('/') + 1
  //   )} 1800w, ${imgUrl.slice(0, imgUrl.lastIndexOf('/'))}/small_${imgUrl.slice(
  //     imgUrl.lastIndexOf('/') + 1
  //   )} 720w`;
  // }

  return fallbackImg;
};

export const getSectionImage = (
  imageUrl: string,
  fallbackImg = '/webImages/bg_Appointment.png'
) => {
  if (imageUrl) {
    return getResponseImage(imageUrl);
  }

  // const imgUrl = imageUrl;

  // if (imgUrl) {
  //   return `${process.env.NEXT_PUBLIC_CMS_CDN_URL}${imgUrl} 1x, ${
  //     process.env.NEXT_PUBLIC_CMS_CDN_URL
  //   }${imgUrl} 2400w, ${process.env.NEXT_PUBLIC_CMS_CDN_URL}${imgUrl.slice(
  //     0,
  //     imgUrl.lastIndexOf('/')
  //   )}/medium_${imgUrl.slice(
  //     imgUrl.lastIndexOf('/') + 1
  //   )} 1800w, ${imgUrl.slice(0, imgUrl.lastIndexOf('/'))}/small_${imgUrl.slice(
  //     imgUrl.lastIndexOf('/') + 1
  //   )} 720w`;
  // }

  return fallbackImg;
};

// export const getIcon = (icon: string) =>
//   Object.keys(ICON).indexOf(icon) > -1
//     ? ICON[icon as keyof typeof ICON]({})
//     : ICON.Euro({});

// eslint-disable-next-line
// export const getIcon = (icon: string) => Euro({});

// export const getIcon = (icon: string) => {
//   const ImportedIcon = libGetIcon(icon);

//   return ImportedIcon;
// };

// we don't know what object it will return
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renameKeys = (obj: object): any =>
  Object.fromEntries(
    Object.entries(obj).map(([key, val]) => [
      key
        .trim()
        .replace(/_/g, '')
        .replace(key[0], key[0].toLowerCase())
        .replace(/[A-Z]{2,}/g, (match) => match.toLowerCase()),
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      processVal(val)
    ])
  );

const processVal = (val: unknown) => {
  if (typeof val !== 'object' || val === null || val === undefined) {
    return val;
  }

  if (Array.isArray(val)) {
    return val.map(renameKeys);
  }

  return renameKeys(val);
};

export const numberWithCommas = (num: number) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const removeCommas = (num: string) => {
  return num.toString().replace(/,/g, '');
};

export const calculateBorrowFunc = (applicantOne: number, applicantTwo = 0) => {
  const jointIncome = applicantOne + applicantTwo;
  const multiplierMortgage = 5;
  const total = jointIncome * multiplierMortgage;
  const totalWithDeposit = total * 1.1;

  return {
    totalWithDeposit: numberWithCommas(
      parseInt(totalWithDeposit.toString(), 10)
    ),
    totalBorrow: numberWithCommas(parseInt(total.toString(), 10)),
    totalIncome: numberWithCommas(jointIncome)
  };
};

const capitalizeAreaWordExceptions = ['and', 'or', 'of', 'upon', 'on', 'the'];

export const capitalizeAreaName = (areaName: string) => {
  return areaName
    .split(' ')
    .map((word, index, words) =>
      index > 0 &&
      index !== words.length - 1 &&
      capitalizeAreaWordExceptions.includes(word)
        ? word
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join(' ');
};

/* eslint-disable no-useless-escape */
export const phoneRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3,4}\)?)\s?-?\s?(\(?\d{4}\)?)$/;

export const phoneRegexValidator = (phone: string) => {
  const specialCharReg = /[~`#$%^&*=\\[\]\\;/{}|\\":<>()_]/g;
  let error = '';

  if (!phoneRegex.test(phone)) {
    error = 'Valid phone number required';
  }

  if (specialCharReg.test(phone ?? '')) {
    error = 'Special characters are not allowed, except +';
  }

  return error;
};

export const specialCharRegex = /^[a-zA-Z0-9\s]*$/;

export const formatPhone = (value: string, previousValue: string) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\+?\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `${currentValue.slice(0, 3)} ${currentValue.slice(3)}`;

    return `${currentValue.slice(0, 3)} ${currentValue.slice(
      3,
      6
    )} ${currentValue.slice(6)}`;
  }

  return value;
};

const clearAndUpper = (text: string) => text.replace(/-/, '').toUpperCase();

export const toPascalCase = (text: string) =>
  text.replace(/(^\w|-\w)/g, clearAndUpper);

export const getMetaTags = (seo: {
  metaTitle?: string;
  metaDescription?: string;
  metaImage?: { data?: string };
  url?: string;
  keywords?: string;
  ogTitle?: string;
  Meta?: Array<{ name: string; content: string }>;
  meta?: Array<{ name: string; content: string }>;
}) => {
  const meta = seo?.meta || seo?.Meta;

  return {
    title: seo?.metaTitle || '',
    description: seo?.metaDescription || '',
    image: seo?.metaImage?.data || '',
    url: '',
    keywords: seo?.keywords || '',
    twitterCreator:
      meta?.find((item) => item.name === 'twitter:creator')?.content || '',
    twitterSite:
      meta?.find((item) => item.name === 'twitter:site')?.content || '',
    twitterSummary:
      meta?.find((item) => item.name === 'twitter:card')?.content || '',
    ogType: meta?.find((item) => item.name === 'og:type')?.content || '',
    ogSiteName:
      meta?.find((item) => item.name === 'og:site_name')?.content || '',
    fbAdmins: meta?.find((item) => item.name === 'fb:admins')?.content || ''
  };
};

export const getEstateAgentRegionCanonical = (path: string) => {
  if (path === 'north-london') {
    return 'estate-agents-in-north-london';
  }
  if (path === 'east-london') {
    return 'estate-agents-in-east-london';
  }
  if (path === 'central-london') {
    return 'estate-agents-in-central-london';
  }
  if (path === 'south-london') {
    return 'estate-agents-in-south-london';
  }
  if (path === 'west-london') {
    return 'estate-agents-in-west-london';
  }
  if (path === 'middlesex') {
    return 'estate-agents-in-middlesex';
  }
  if (path === 'surrey') {
    return 'estate-agents-in-surrey';
  }

  return '';
};

export const parseHeroBannerSection = (
  heroBanner: {
    title?: string;
    subtitle?: string;
    text?: string;
    buttons?: { label?: string; href?: string }[];
    image?: {
      data?: {
        attributes?: {
          url?: string;
          alternativeText?: string;
        };
      }[];
    };
  }[],
  typeName = 'Banner'
) => {
  const heroBannerRaw = heroBanner.find(
    (section: Record<string, unknown>) => section.type === typeName
  );
  const bannerButton1 = (heroBannerRaw?.buttons || [])[0];
  const bannerButton2 = (heroBannerRaw?.buttons || [])[1];
  const heroBannerSection = {
    title: heroBannerRaw?.title || '',
    subheading: heroBannerRaw?.subtitle || '',
    subtitle: heroBannerRaw?.text || '',
    btnPrimary: !bannerButton1
      ? null
      : {
          label: bannerButton1?.label || '',
          href: bannerButton1?.href || '',
          colorProp: 'primary'
        },
    btnSecondary: !bannerButton2
      ? null
      : {
          label: bannerButton2?.label || '',
          href: bannerButton2?.href || '',
          colorProp: 'secondary'
        },
    backgroundImg: getHeroBannerImage(
      (heroBannerRaw?.image?.data || [])[0]?.attributes?.url || ''
    ),
    backgroundImgAltText:
      (heroBannerRaw?.image?.data || [])[0]?.attributes?.alternativeText ||
      'alt-text'
  };

  return heroBannerSection as LibHeroContainerProps;
};

export const parseExploreSection = (
  explore: {
    title?: string;
    id?: string;
    links: { id?: string; href?: string; label?: string }[];
  }[],
  typeName = 'ExploreFoxtons',
  cardItemName = 'ExploreFoxtonsItem'
) => {
  const exploreTitleRaw = explore.find(
    (section: Record<string, unknown>) => section.type === typeName
  );
  let exploreCardsRaw = [];

  exploreCardsRaw = explore.filter(
    (section: Record<string, unknown>) => section.type === cardItemName
  );
  const exploreCardsArray = [];

  const cardLinkList2 = [];

  for (let i = 0; i < exploreCardsRaw?.length || 0; i += 1) {
    cardLinkList2[i] = exploreCardsRaw[i].links.map(
      (item: Record<string, unknown>) => {
        const card = {
          id: `${item.id}` || '',
          label: (item.label as string) || '',
          href: (item.href as string) || ''
        };

        return card;
      }
    );

    exploreCardsArray[i] = {
      id: `${exploreCardsRaw[i]?.id}` || '',
      title: exploreCardsRaw[i]?.title || '',
      linkList: cardLinkList2[i] || []
    };
  }

  const exploreSection = {
    title: exploreTitleRaw?.title || '',
    cardsContent: exploreCardsArray
  };

  return exploreSection;
};

export const parseHorizontalScrollSection = (
  horizontalScroll: {
    title?: string;
    titleText?: { title?: string };
    id?: string;
    richText?: string;
    buttons?: { label?: string; href?: string }[];
    image?: {
      data?: {
        attributes?: {
          url?: string;
          alternativeText?: string;
        };
      }[];
    };
  }[],
  sectionName = 'horizontalScroll'
) => {
  const sectionSlidesRaw = horizontalScroll.filter(
    (section: Record<string, unknown>) => section.type === sectionName
  );

  const sectionSlides = sectionSlidesRaw?.map((item) => {
    const leftImage = (item?.image?.data || [])[0]?.attributes;
    const rightImage = (item?.image?.data || [])[1]?.attributes;
    const button = (item?.buttons || [])[0];
    const content = item.richText || '';

    return {
      id: item?.id || '',
      leftCol: {
        text: item?.title || '',
        image: {
          src: getResponseImage(leftImage?.url || ''),
          alt: leftImage?.alternativeText || 'alt-text'
        }
      },
      rightCol: {
        alt: getResponseImage(
          item?.titleText?.title
            ? leftImage?.alternativeText || 'alt-text'
            : rightImage?.alternativeText || 'alt-text'
        ),
        src: item?.titleText?.title
          ? leftImage?.url || ''
          : rightImage?.url || ''
      },
      center: {
        content,
        linkButton: { href: button?.href || '', label: button?.label || '' }
      }
    };
  });

  return sectionSlides;
};

export const parseFoxtonsServices = (
  foxtonsServices: {
    title?: string;
    cards?: {
      id?: string;
      title?: string;
      simpleText?: string;
      link1Text?: string;
      link1?: string;
      link2Text?: string;
      link2?: string;
      text?: string;
    }[];
  }[],
  titleName = 'foxtonsServiceTitle',
  cardName = 'foxtonsServiceCard'
) => {
  const foxtonsServicesCardsArrayRaw = foxtonsServices.find(
    (section: Record<string, unknown>) => section.section === cardName
  );

  const foxtonsServiceTitle =
    foxtonsServices.find(
      (section: Record<string, unknown>) => section.type === titleName
    )?.title ||
    foxtonsServicesCardsArrayRaw?.title ||
    '';

  const foxtonsServicesCardsArray = foxtonsServicesCardsArrayRaw?.cards?.map(
    (item) => ({
      id: item?.id || '',
      title: item?.title || '',
      text: item?.simpleText || '',
      button1: {
        text: item?.link1Text || '',
        href: item?.link1 || ''
      },
      button2: {
        text: item?.link2Text || '',
        href: item?.link2 || ''
      },
      bullets: getBulletPoints(item?.text || '', 'foxtonsServiceItem').map(
        (point) => ({
          id: point.id,
          text: point.point
        })
      )
    })
  );

  const foxtonsServicesCardSection = {
    title: foxtonsServiceTitle,
    foxtonsServicesCards: foxtonsServicesCardsArray
  };

  return foxtonsServicesCardSection;
};

export function parseRentalYieldCalculator(
  rentalYieldCalculator: {
    title?: string;
    iconCard?: { title?: string; text?: string; icon?: string };
    buttons?: Array<{ href?: string; label?: string }>;
    richTextCard?: {
      title?: string;
      text?: string;
      link1Text?: string;
      link2Text?: string;
      link1?: string;
      link2?: string;
    };
  }[],
  titleType = 'title',
  noResultType = 'noResult',
  resultType = 'result'
) {
  const title =
    rentalYieldCalculator.find(
      (section: Record<string, unknown>) => section.type === titleType
    )?.title || '';

  const NoResultRaw = rentalYieldCalculator.find(
    (section: Record<string, unknown>) => section.type === noResultType
  );

  const noResult = {
    heading: NoResultRaw?.iconCard?.title || '',
    subHeading: NoResultRaw?.iconCard?.text || '',
    icon: NoResultRaw?.iconCard?.icon || 'HomeFilled'
  };

  const resultRaw = rentalYieldCalculator.find(
    (section: Record<string, unknown>) => section.type === resultType
  );

  const button = (resultRaw?.buttons || [])[0];

  const result = {
    heading: resultRaw?.richTextCard?.title || '',
    subHeading: resultRaw?.richTextCard?.text || '',
    buttonOne: {
      href: resultRaw?.richTextCard?.link1 || '',
      label: resultRaw?.richTextCard?.link1Text || ''
    },
    buttonTwo: {
      href: resultRaw?.richTextCard?.link2 || '',
      label: resultRaw?.richTextCard?.link2Text || ''
    },
    buttonThree: { href: button?.href || '', label: button?.label || '' }
  };

  return { title, miniCardNoResults: noResult, miniCardResults: result };
}

export function parseFAQ(
  faq: {
    title: string;
    buttons: { href: string; label: string }[];
    id: string;
    titleText: { title: string; text: string };
    richText: string;
  }[],
  titleType = 'faqTitle',
  faqItemType = 'faqItem'
) {
  const title =
    faq.find((section: Record<string, unknown>) => section.type === titleType)
      ?.title || '';

  const faqItemsRaw = faq.filter(
    (section: Record<string, unknown>) => section.type === faqItemType
  );

  const faqItems = faqItemsRaw.map((item) => {
    const buttons = (item?.buttons || [])[0];

    return {
      id: item?.id || '',
      label: item?.titleText?.title || '',
      panelContent: {
        textLarge: item?.titleText?.text || '',
        richTextContent: item?.richText || '',
        btnPrimary: !item?.buttons.length
          ? null
          : {
              href: buttons?.href || '',
              label: buttons?.label || ''
            }
      }
    };
  });

  return { title, accordionSummaryArray: faqItems };
}

export const parseHeaderMenu = (data: HeaderType) => {
  const propsHeader = data?.map((item) => ({
    id: `${item?.id}`,
    title: item?.attributes?.title,
    titleLink: item?.attributes?.url,
    subtitle: item?.attributes?.children?.data.map((ch) => ({
      id: `${ch.id}`,
      tag: item?.attributes?.title,
      subtitle: ch?.attributes?.title,
      subtitleLink: ch?.attributes?.url,
      identifier: ch?.attributes?.identifier,
      childNav: ch?.attributes?.children?.data.map((gch) => ({
        id: `${gch?.id}`,
        tag: item?.attributes?.title,
        childName: gch?.attributes?.title,
        childNavLink: gch?.attributes?.url
      }))
    }))
  }));

  return propsHeader;
};

export const parseFooterMenu = (data) => {
  const footerProps = {
    bottomSection: data?.bottomSection,
    footerSections: data?.footerSections,
    socialMediaIcons: data?.socialMediaIcons,
    modernSlaveryStatementsModal: data?.modernSlaveryStatementsModal
  };

  return footerProps;
};

type StrapiBreadcrumbItem = {
  id: number;
  label: string;
  customUrl?: string;
  page: {
    data: {
      id: number;
      attributes: {
        strapiapi: string;
        pageName: string;
        url: string;
      };
    };
  };
};

type PageBreadcrumb = {
  label: string;
  theme: 'dark' | 'light';
  hide: boolean;
  breadcrumbItems: StrapiBreadcrumbItem[] | null;
};

const parseBreadcrumbItems = (
  breadcrumbs: PageBreadcrumb,
  currentURL?: string
): BreadcrumbItem[] => {
  const { breadcrumbItems } = breadcrumbs;
  const home: BreadcrumbItem = { label: 'Home', href: '/' };
  const middle: BreadcrumbItem[] = [];
  const last: BreadcrumbItem = {
    label: breadcrumbs.label,
    href: currentURL ? `/${currentURL}` : ''
  };

  breadcrumbItems?.forEach(({ label, page, customUrl }) => {
    const url = page?.data?.attributes?.url || '';

    if (label === home.label && url === home.href) {
      return;
    } else if (customUrl) {
      middle.push({
        label,
        href: customUrl
      });
    } else {
      middle.push({
        label,
        href: url.startsWith('/') || !url ? url : `/${url}`
      });
    }
  });

  return [home, ...middle, last];
};

/**
 * Parse Strapi breadcrumbs to props for Breadcrumbs components
 * @param data - Object with Strapi breadcrumbs data and additional options
 * @param data.breadcrumbs - Strapi breadcrumbs data
 * @param data.url - URL for the last breadcrumb item
 * @param data.replaceLast - Object with keys and values to replace in the last breadcrumb item label
 * @returns props for Breadcrumbs component, or null if breadcrumbs are missing
 */
export const parseBreadcrumbsProps = ({
  breadcrumbs,
  url,
  replaceLast
}: {
  breadcrumbs?: PageBreadcrumb | null;
  url?: string;
  replaceLast?: Record<string, string>;
}) => {
  if (!breadcrumbs) {
    return null;
  }

  const items = parseBreadcrumbItems(breadcrumbs, url);

  /*  Replaces {key} with dynamic values in the last breadcrumb item label */
  if (replaceLast) {
    const lastItem = items[items.length - 1];

    if (lastItem) {
      lastItem.label = Object.entries(replaceLast).reduce(
        (acc, [key, value]) => acc.replaceAll(`{${key}}`, value),
        lastItem.label
      );
    }
  }

  return {
    items,
    theme: breadcrumbs?.theme ?? 'dark',
    hide: breadcrumbs?.hide ?? false
  };
};

type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown): string {
  return toErrorWithMessage(error).message;
}

const pageAssetsUrl = process.env.NEXT_PUBLIC_ASSET_URL;

export const photoStringPageAssetsStaff = (imgName: string) => {
  const string = `${pageAssetsUrl}/staff/w/480/${imgName.replace(
    'edited_',
    ''
  )}`;

  return string;
};

export const getFloorPlanImage = (assets: AssetInfo['assets']) => {
  return (
    `${process.env.NEXT_PUBLIC_FOXTONS_ASSET_URL}/w/2200/${
      assets?.floorplan?.small?.timestamp
    }/${assets?.floorplan?.small?.filename.split('_')[0]}_floorplan.png` || ''
  );
};

export const photoAssetUrl = (
  pic: string,
  imgName: string,
  timestamp: string,
  width = '480'
) => {
  if (!pic) return '';

  const assetsUrl = process.env.NEXT_PUBLIC_FOXTONS_ASSET_URL || '';

  const assetUrl = `${assetsUrl}/w/${width}/${timestamp}/${imgName.replace(
    'edited_',
    ''
  )}`;

  return assetUrl;
};

export const photoStringLocalLifeCardsPic = (imgId: string) => {
  if (!imgId) {
    return '';
  }

  const fixingId = imgId.length === 4 ? imgId : `0${imgId}`;

  const reverseString = fixingId.split('').reverse().join('');

  const firstRevDoubleNum = reverseString.slice(0, 2);

  const secondRevDoubleNum = reverseString.slice(2, 4);

  const refactoredNum = `${pageAssetsUrl}/poi/${firstRevDoubleNum}/${secondRevDoubleNum}/000/w/1600/000${fixingId}.jpg`;

  return refactoredNum;
};

export const getPropertyUrl = (
  instructionType: string,
  shortPostcode: string,
  propertyReference: string
) => {
  const searchPagesPaths: { [key: string]: string } = {
    sale: 'properties-for-sale',
    letting: 'properties-to-rent',
    short_letting: 'short-let-properties'
  };

  return `/${searchPagesPaths[instructionType]}/${shortPostcode}/${propertyReference}`;
};

export const instructionMappings: { [key: string]: string } = {
  SAL: 'sale',
  LON: 'letting',
  SHO: 'short_letting',
  LET: 'letting'
};

export const inverseInstructionMappings: { [key: string]: string } = {
  sale: 'SAL',
  letting: 'LON',
  short_letting: 'SHO'
};

const shortPostCodeRegex =
  /^([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA)$/;

export const isUkShortPostCode = (postcode: string) =>
  shortPostCodeRegex.test(postcode?.toUpperCase());

export function capitalizeAndUpperUKPostcodes(inputString: string) {
  return inputString
    ?.replace(/[- _]/g, ' ')
    ?.split(' ')
    .map((word) => {
      if (isUKLongPostCode(word) || isUkShortPostCode(word)) {
        // Convert UK short postcode to uppercase
        return word.toUpperCase();
      } else if (
        word.length >= 7 &&
        /^[A-Za-z]{1,2}\d{1,2}[A-Za-z]?\s?\d[A-Za-z]{2}$/.test(word)
      ) {
        // Convert UK long postcode to uppercase
        return word.toUpperCase();
      } else {
        // Capitalize the first letter of each word
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    })
    .join(' ');
}

export function convertToPerWeek(price: string | number) {
  return Math.round(Number(price) / (52 / 12));
}

export function convertToPerMonth(price: string | number) {
  return Math.round(Number(price) * (52 / 12));
}

export function removeByKey(obj: HttpResponseDataType, keyToRemove: string) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    obj.forEach((item, index) => {
      obj[index] = removeByKey(item, keyToRemove);
    });
  } else {
    Object.keys(obj).forEach((key) => {
      if (key === keyToRemove) {
        delete obj[key];
      } else {
        obj[key] = removeByKey(obj[key], keyToRemove);
      }
    });
  }

  return obj;
}

export const propertiesFor = (slugString: string | string[]) => {
  const typeProp = slugString;
  const {
    propertiesForAuction,
    propertiesForLongLet,
    propertiesForSale,
    propertiesForShortLet,
    housesForSale,
    flatsForSale,
    apartmentsForSale,
    housesForLongLet,
    flatsForLongLet,
    apartmentsForLongLet,
    housesForShortLet,
    flatsForShortLet,
    apartmentsForShortLet
  } = propertyListPagePathConstant;

  switch (typeProp) {
    case propertiesForLongLet:
    case housesForLongLet:
    case flatsForLongLet:
    case apartmentsForLongLet:
    case LONG_LET:
    case 'LL':
      return LONG_LET;
    case propertiesForShortLet:
    case housesForShortLet:
    case flatsForShortLet:
    case apartmentsForShortLet:
    case SHORT_LET:
    case 'SL':
      return SHORT_LET;
    case propertiesForSale:
    case housesForSale:
    case apartmentsForSale:
    case flatsForSale:
    case SALE:
    case propertiesForAuction:
    case 'SS':
      return SALE;
    default:
      return SALE;
  }
};

export type AuthActionSessionData = {
  hasLoggedIn: boolean;
  redirectUrl: string;
  actionName: string;
  formData?: unknown;
  propertyData?: {
    postcodeShort: string;
    locationName: string;
    streetName: string;
    isSneakPeek: boolean;
    price: string;
    bath: string;
    bed: string;
    area: string;
    image: string;
  };
};

export function saveAuthActionInSession(action: AuthActionSessionData) {
  const { hasLoggedIn, propertyData, redirectUrl, actionName, formData } =
    action;

  const dataToSave = {
    hasLoggedIn,
    redirectUrl,
    action: actionName,
    formData,
    propertyData: !propertyData
      ? undefined
      : {
          image: propertyData.image,
          price: propertyData.price,
          bath: propertyData.bath,
          bed: propertyData.bed,
          area: propertyData.area,
          isSneakPeek: propertyData.isSneakPeek,
          address: `${propertyData.streetName}, ${propertyData.locationName}, ${propertyData.postcodeShort}`
        }
  };

  sessionStorage.setItem('propertyAction', JSON.stringify(dataToSave));
}

export const getPrice = (
  instructionType: string,
  priceTo: number,
  priceFrom: number
) => {
  if (instructionType === 'sale') {
    if (priceTo !== priceFrom) {
      return `£${numberWithCommas(Math.round(priceFrom))} - £${numberWithCommas(
        Math.round(priceTo)
      )}`;
    }

    return `£${numberWithCommas(Math.round(priceFrom))}`;
  }

  return `£${numberWithCommas(Math.round(priceFrom))} pw`;
};

export const getPriceRangeNewHomes = (priceTo: number, priceFrom: number) => {
  if (priceTo !== priceFrom) {
    return `£${numberWithCommas(Math.round(priceFrom))} - £${numberWithCommas(
      Math.round(priceTo)
    )}`;
  }

  return `£${numberWithCommas(Math.round(priceFrom))}`;
};

export const getAuthActionFromSessionStorage = () => {
  const propertyAction =
    typeof window === 'undefined'
      ? undefined
      : JSON.parse(sessionStorage.getItem('propertyAction') || '{}');

  const propertyData = propertyAction
    ? propertyAction?.propertyData
    : undefined;

  const action = propertyAction ? propertyAction?.action : undefined;

  // TODO: it is not a reliable flag, user?.isLoggedIn is better
  const hasLoggedIn = propertyAction?.hasLoggedIn || false;
  const redirectUrl = propertyAction?.redirectUrl || '';
  const formData = propertyAction?.formData || undefined;

  return { action, propertyData, hasLoggedIn, redirectUrl, formData };
};

export const get12H = (hour: number) => {
  const AmOrPm = hour >= 12 ? 'pm' : 'am';
  const formattedHour = hour % 12 || 12;

  return `${formattedHour}${AmOrPm}`;
};

export const getFormattedValue = (value: string) => {
  const formattedResult =
    value !== '' && value.match(/^\d*$/)
      ? numberWithCommas(Number(value.replace(/,/g, '')))
      : value;

  return formattedResult;
};

export const getEPCImage = (
  potential: string,
  current: string,
  type: string
) => {
  potential = potential + '';
  current = current + '';

  const augmentedPotentialValue = potential.padStart(3, '0');
  const augmentedCurrentValue = current.padStart(3, '0');

  if (
    augmentedPotentialValue.includes('000') ||
    augmentedCurrentValue.includes('000')
  ) {
    return null;
  }

  return `${pageAssetsUrl}/${type}/w/238/${augmentedCurrentValue}/${augmentedPotentialValue}.gif`;
};

export function parseNearestStationData(
  stationData: {
    id?: string;
    simpleName?: string;
    name?: string;
    zones?: string;
    lines?: {
      name: string;
      id: string;
      textColour: string;
      colour: string;
      mode: string;
    }[];
  }[]
) {
  const parsedStationsData = stationData?.map((station) => ({
    id: station?.id,
    name: station?.name,
    zone: station?.zones,
    lines: station?.lines
      ?.filter((line) => line.id !== 'tfl-rail')
      .map((line) => ({
        name: line?.name,
        id: line?.id,
        color: line?.colour,
        textColor: line?.textColour,
        mode: line?.mode
      }))
  }));

  return parsedStationsData;
}

export const setStampDutyIsUkResident = (isUkResident: boolean) => {
  if (typeof window === 'undefined') {
    return null;
  }

  const foxtonsStr = localStorage.getItem('foxtons');
  let foxtonsObj = !foxtonsStr ? null : JSON.parse(foxtonsStr);

  if (!foxtonsObj) {
    foxtonsObj = { stampDutyIsUkAddress: isUkResident };
  } else {
    foxtonsObj = { ...foxtonsObj, stampDutyIsUkAddress: isUkResident };
  }

  localStorage.setItem('foxtons', JSON.stringify(foxtonsObj));
};

export const getStampDutyIsUkResident = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  const foxtonsStr = localStorage.getItem('foxtons');
  const obj = foxtonsStr
    ? JSON.parse(foxtonsStr)?.stampDutyIsUkAddress
    : undefined;

  return !foxtonsStr || typeof obj !== 'boolean' ? null : Boolean(obj);
};

export const hasAnyQueryParam = (url: string) => {
  const searchParams = new URLSearchParams(url);

  return searchParams.toString().length > 0;
};

export const getPriceForPropertyDetail = (
  priceTo: number,
  priceFrom: number,
  unit?: string
) => {
  if (priceTo !== priceFrom) {
    return `£${numberWithCommas(Math.round(priceFrom))}${
      unit ? ` ${unit}` : ''
    } to £${numberWithCommas(Math.round(priceTo))}${unit ? ` ${unit}` : ''}`;
  }

  return `£${numberWithCommas(Math.round(priceFrom))}${unit ? ` ${unit}` : ''}`;
};

export const mapBedroomsToName = (bedrooms: number) => {
  if (bedrooms === 0) {
    return 'Studio';
  } else if (bedrooms === 1) {
    return '1 Bed';
  } else if (bedrooms === 2) {
    return '2 Bed';
  } else if (bedrooms >= 3) {
    return '3+ Bed';
  } else if (bedrooms < 0) {
    return 'Various';
  } else {
    return 'Unknown';
  }
};

export const setPropertySearchHistory = (propertyId: string) => {
  const storedData = localStorage.getItem('search_history');
  let arr = { properties: [] };

  if (storedData) {
    arr = JSON.parse(storedData);

    const propertyIndex = arr.properties.indexOf(propertyId);

    if (propertyIndex !== -1) {
      arr.properties.splice(propertyIndex, 1);
      arr.properties.unshift(propertyId);
    } else {
      arr.properties.unshift(propertyId);
    }
  } else {
    arr.properties.unshift(propertyId);
  }

  //Saves last 10 ids to local storage
  arr.properties = arr.properties.slice(0, 10);

  localStorage.setItem('search_history', JSON.stringify(arr));
};

export const getSearchHistory = () => {
  const storedData = localStorage.getItem('search_history');
  let parsedData = [];

  if (storedData) {
    parsedData = JSON.parse(storedData);
  }

  return parsedData;
};

export const getSearchTypeHistory = (): string => {
  try {
    const storedData = JSON.parse(localStorage.getItem('last_search') || '{}');
    let parsedData = storedData?.search_type || 'sale';

    if (
      parsedData === 'short-let-properties' ||
      parsedData === 'long-let-properties'
    ) {
      parsedData = 'letting';
    } else {
      parsedData = 'sale';
    }

    return parsedData;
  } catch (error) {
    return 'sale';
  }
};

// Function returns boolean by checking whether a given string is present in array of objects
export const containsString = (array: any, searchString: string) => {
  return array.some((obj) =>
    Object.values(obj).some((value) => value === searchString)
  );
};

export const getInterestOnly = (
  propertyPrice,
  deposit,
  interestRate,
  duration
) => {
  const loan = Number(propertyPrice) - Number(deposit);
  let repay = Math.round(
    (loan * duration * (interestRate / 100)) / (12 * duration)
  );

  repay = repay < 0 ? 0 : repay;

  return numberWithCommas(repay);
};

/* eslint-disable @typescript-eslint/naming-convention */
export const getRepayments = (
  propertyPrice,
  deposit,
  interestRate,
  duration,
  overPay
) => {
  if (interestRate < 1)
    return { repayment_per_month_with_overpay: 0, repayment_per_month: 0 };

  const rate = Number(interestRate);
  // const r = Number(rate) / 100;
  const rate_per_month = rate / 1200; // decimal rate per month
  const p = Number(propertyPrice) - Number(deposit);
  const m = Number(duration) * 12; // term in months
  const loan = Math.pow(1 + rate_per_month, m);
  const repayment_per_month = Math.ceil(p / ((1 - 1 / loan) / rate_per_month));

  if (overPay) {
    const overpay = Number(overPay?.payEachMonth);
    const lumpsum1 = Number(overPay?.pay);
    // const lumpsum1_year = 2;
    const overpayment_per_month = overpay;
    let repayment_per_month_with_overpay = repayment_per_month;
    // let payextra = false;

    if (overpayment_per_month > 0 || lumpsum1 > 0) {
      // payextra = true;
      repayment_per_month_with_overpay = repayment_per_month + overpay;

      return { repayment_per_month_with_overpay, repayment_per_month };
    }
  }

  // calc total interest on loan:

  return {
    repayment_per_month: repayment_per_month,
    repayment_per_month_with_overpay: repayment_per_month
  };
};
/* eslint-enable @typescript-eslint/naming-convention */

export const saveMortgageData = (data) => {
  try {
    // Convert data to JSON string
    const jsonData = JSON.stringify(data);

    // Save data to local storage
    localStorage.setItem('mortgage', jsonData);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error saving mortgage data:', error);
  }
};

export const getGraphData = (
  selectedDuration: number,
  price: string,
  deposit: string,
  // eslint-disable-next-line @typescript-eslint/default-param-last
  variant = 'Interest-only',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  repay
) => {
  const p = Number(price) - Number(deposit);
  const m = Number(selectedDuration * 12);

  if (variant === 'Interest-only') {
    const graphPoints = [];

    for (let i = 0; i < m; i++) {
      graphPoints.push(p);
    }

    return graphPoints;
  } else if (variant === 'Repayment') {
    const arr = [];
    const perYear = Number(p) / Number(selectedDuration);
    let remainingAmount = p;

    for (let i = 0; i < Number(selectedDuration); i++) {
      remainingAmount = remainingAmount - perYear;
      arr.push(remainingAmount);
    }

    return arr;
  }
};

export const getMortgageValues = (
  interestRate,
  price,
  deposit,
  years,
  overpayAmount = 0,
  lumpsum1Amount = 0,
  overpayments = false,
  lumpsum1year = 2
) => {
  const rate = Number(interestRate) / 100;
  const ratePerMonth = rate / 12;
  const p = Number(removeCommas(price)) - Number(removeCommas(deposit));

  const m = Number(years) * 12;
  const overpay = Number(removeCommas(overpayAmount));
  const lumpsum1 = Number(removeCommas(lumpsum1Amount));
  let lumpsum1Year = 1;

  const mortgagePaymentsFlexible = [];
  const mortgagePaymentsRepayment = [];
  const mortgagePaymentsInterestOnly = [];

  if (overpayments) {
    lumpsum1Year = lumpsum1year;
  }

  const loan = Math.pow(1 + ratePerMonth, m);
  const repaymentPerMonth = Math.ceil(p / ((1 - 1 / loan) / ratePerMonth));
  const overpaymentPerMonth = overpay;
  let repaymentPerMonthWithOverpay = repaymentPerMonth;

  let payExtra = false;

  if (overpaymentPerMonth > 0 || lumpsum1 > 0) {
    payExtra = true;
    repaymentPerMonthWithOverpay = repaymentPerMonth + overpay;
  }

  // const totalInterest = Math.round(repaymentPerMonth * m - p);
  // const totalInterestOverpay = Math.round(
  //   (repaymentPerMonth - overpay) * m - p
  // );

  // let lnInt = 0;
  // let lnIntOverpay = 0;
  let lnOwed = p;
  let lnOwedOverpay = p;
  let lnInterest = 0;
  // let monthsSaved = 0;

  for (let i = 0; i < m; i++) {
    lnInterest = Math.ceil(lnOwed * ratePerMonth);
    const lnInterestOverpay = Math.round(
      (lnOwedOverpay - overpay) * ratePerMonth
    );

    // lnInt += lnInterest;
    // lnIntOverpay += lnInterestOverpay;
    lnOwed = lnOwed - repaymentPerMonth + lnInterest;

    if (payExtra && lumpsum1Year * 12 === i) {
      lnOwedOverpay =
        lnOwedOverpay -
        repaymentPerMonthWithOverpay -
        lumpsum1 +
        lnInterestOverpay;
      mortgagePaymentsFlexible.push(lnOwedOverpay < 0 ? 0 : lnOwedOverpay);
    } else if (payExtra) {
      lnOwedOverpay =
        lnOwedOverpay - repaymentPerMonthWithOverpay + lnInterestOverpay;
      mortgagePaymentsFlexible.push(lnOwedOverpay < 0 ? 0 : lnOwedOverpay);
    }

    // if (lnOwedOverpay < 0) {
    //   monthsSaved++;
    // }

    mortgagePaymentsInterestOnly.push(p);
    mortgagePaymentsRepayment.push(lnOwed < 0 ? 0 : lnOwed);
  }

  return {
    repaymentPerMonth:
      interestRate < 1 ? 0 : numberWithCommas(repaymentPerMonth),
    repaymentPerMonthWithOverpay:
      interestRate < 1 ? 0 : numberWithCommas(repaymentPerMonthWithOverpay),
    mortgagePaymentsFlexible,
    mortgagePaymentsRepayment,
    mortgagePaymentsInterestOnly
  };
};

export const convertToMonthName = (dateString: string): string => {
  const months: Record<string, string> = {
    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sep: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December'
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [monthAbbreviation, day] = dateString?.split(' ');
  const monthName = months[monthAbbreviation];

  return monthName;
};

const typeNames = {
  mews: 'Mews house',
  commercial: 'Commercial property',
  'house detached': 'House',
  'house end of terrace': 'House',
  'house semi-detached': 'House',
  'house terraced': 'House'
};

//below function is to match the logic which is currently working for ldjson foxtons.co
export function getPropertyType(property: PropertyDetails) {
  if (!property?.type) {
    return null;
  }

  if (property?.propertyReference === 'chpk4339234') {
    return 'Dwelling';
  }

  if (property?.propertyReference === 'chpk3378579') {
    return 'Penthouse';
  }

  if (property?.propertyReference === 'chpk4337047') {
    return 'Apartment';
  }

  if (typeNames[property?.type]) {
    return typeNames[property?.type];
  }

  if (property?.type === 'flat') {
    if (!property?.bedrooms) {
      return 'Studio';
    }

    if (property?.instructionType === 'sale') {
      if (property?.priceFrom >= 1000000) {
        return 'Apartment';
      }
    } else {
      if (property?.priceFrom >= 1500) {
        return 'Apartment';
      }
    }
  }

  return property?.type.charAt(0).toUpperCase() + property?.type.slice(1);
}

export function formatDateTime(date: Date): string {
  return format(date, 'yyyy-MM-dd HH:mm:ss');
}

export function getIsVirtualOffice({
  division,
  officeType,
  location
}: {
  division?: string;
  officeType?: string;
  location?: { lat: number; log: number } | null;
}): boolean {
  return (
    division === 'Virtual' ||
    division === 'New Homes' ||
    officeType === 'other' ||
    location == null
  );
}

export const convertPrice = (price: string, float?: boolean) => {
  const convertedPrice = float ? price : parseInt(price, 10);

  const pounds = Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumSignificantDigits: float ? undefined : 10,
    trailingZeroDisplay: 'stripIfInteger'
  });

  return pounds.format(convertedPrice as number);
};

export function getSearchTypeForMetaTitle(basePath: string): string {
  const searchType = propertiesFor(basePath);

  if (basePath.includes('auction')) {
    return 'Auction';
  } else if (searchType === 'letting') {
    return 'Rent';
  } else if (searchType === 'sale') {
    return 'Sale';
  } else {
    return 'Short Let';
  }
}

/**
 * get canonical link for local life and living in pages,
 * by removing the last part of the url if it is a short postcode
 * for example: Church-end-e18 -> church-end
 * query params are not removed
 */
export function getLocalLifeLivingInCanonicalLink(url: string): string {
  return url.toLowerCase().replace(/-[a-z]{1,2}(([0-9][a-z])|[0-9]{1,2})/, '');
}

export const getCanonicalUrl = (canonical: string) => {
  if (canonical.includes('apartments-')) {
    canonical = canonical.replace('apartments-', 'flats-');
  }
  return canonical;
};
